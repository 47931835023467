.section {
  padding: 4rem 6rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for desktop */
  grid-template-rows: repeat(2, 1fr); /* 2 rows for desktop */
  row-gap: 50px;
  column-gap: 50px; /* Spacing between images */
}

.image-item {
  width: 100%;
  height: 100%; /* Dynamic width for 4 images per row */
  box-sizing: border-box; /* Ensure consistent sizing */
  border: 2px solid var(--color-border-line);
  border-radius: 20px;
}

/* Mobile view adjustments */
@media only screen and (max-width: 768px) {
  .section {
    padding: 1rem 2rem;
  }

  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for the first 3 rows */
    grid-template-rows: repeat(3, auto) 2fr auto; /* First 3 with auto, last 2 fixed */
  }

  /* Target the last two image items with high specificity */
  .image-item:nth-child(n+7) {
    grid-column: 1; /* Start these images on a new row */
    width: 100%; 
  }
}

