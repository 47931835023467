.criya__navbar {
    display: flex;
    background-color: black;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
  }
  
  .criya__navbar_logo img{
    width: 6rem;
    height: 4rem;
    
  }
  .criya__navbar-links_li {
    color: var(--color-navbar-links);
    padding: 0rem 1.5rem;
    font-weight: 600;
    font-size: 0.9rem;
  }
  /* //dropdown--- */
  .criya__navbar-dropdown_li {
    color: var(--color-navbar-links);
    padding: 0rem 1.5rem;
    font-weight: 600;
    font-size: 0.9rem;
    position: relative;
    transition: width 2s linear 1s;
  }
  .criya_navbar_dropdown_title {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
  }
  .criya__navbar-dropdown_li:hover .criya_navbar_dropdown-links {
    height: 15rem;
  }
  .criya_navbar_dropdown_arrow {
    transition: all 500ms linear 0s;
  }
  .criya__navbar-dropdown_li:hover .criya_navbar_dropdown_arrow {
    transform: rotate(180deg);
    color: var(--color-blue);
  }
  .criya_navbar_dropdown-links {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.2rem;
    background: white;
    text-align: start;
    z-index: 10;
    transition: all 500ms linear 0s;
    height: 0;
    overflow: hidden;
    border-radius: 0.3rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .criya__navbar-dropdown-link {
    padding: 0.5rem 1rem;
  }
  .criya__navbar-dropdown-link:hover {
    background: slategray;
    color: white;
  }
  /* //dropdown end--- */
  
  .criya__navbar-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    color: var(--color-navbar-links);
    align-items: center;
  }
  .criya__navbar-links_li:hover {
    color: var(--color-border-line);
  }
  .criya__navbar-links_container {
    display: flex;
    align-items: center;
    margin-inline: auto;
  }
  
  .active {
    color: var(--color-border-line);
    /* border-bottom: 4px solid var(--color-blue); */
  }
  .criya__navbar-menu {
    display: none;
    position: relative;
  }
  
  @media screen and (max-width: 1000px) {
    .criya__navbar {
      padding: 1rem;
    }
    .criya__navbar-links_logo_container {
      width: 100%;
    }
    .criya__navbar-links_container {
      display: none;
    }
    .criya__navbar-menu {
      display: flex;
      flex-direction: column;
    }
    .criya__navbar-menu_container-links {
      position: absolute;
      right: -0.9rem;
      margin: 0.5rem auto;
      background-color: var(--color-bg);
      padding: 1rem 1rem;
      border-radius: 0.4rem;
      z-index: 15;
      width: 99vw;
      box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.19);
    }
    li {
      text-align: right;
      color: var(--color-navbar-links);
      font-family: var(--font-family);
      padding: 0.5rem 0rem;
      font-weight: bold;
    }
    .criya__navbar_name {
      font-size: 1.6rem;
    }
  
    /* //dropdown--- */
    .criya__navbar-dropdown_li {
      color: var(--color-navbar-links);
      padding: 0rem;
      font-weight: 600;
      font-size: 0.9rem;
      position: relative;
    }
    .criya_navbar_dropdown_title {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 0.2rem;
      font-size: 1rem;
      color: var(--color-text);
      font-family: var(--font-family);
      padding: 0.5rem 0rem;
      font-weight: bold;
      width: fit-content;
      margin-inline-start: auto;
    }
    .criya__navbar-dropdown_li:hover .criya_navbar_dropdown-links {
      height: auto;
    }
    .criya_navbar_dropdown_arrow {
      transition: all 500ms linear 0s;
      font-size: 1.1rem;
    }
    .criya__navbar-dropdown_li:hover .criya_navbar_dropdown_arrow {
      transform: none;
      color: var(--color-text);
    }
    .criya_navbar_dropdown-links {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 0.2rem;
      background: white;
      text-align: start;
      z-index: 10;
      transition: all 500ms linear 0s;
      height: auto;
      overflow: hidden;
      border-radius: 0.3rem;
      box-shadow: none;
    }
    .criya__navbar-dropdown-link {
      padding: 0.5rem 1rem;
    }
    .criya__navbar-dropdown-link:hover {
      background: slategray;
      color: white;
    }
    /* //dropdown end--- */
  }
  
  @media screen and (max-width: 550px) {
    .criya__navbar-menu {
      margin-left: 0.5rem;
    }
  }