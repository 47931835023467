@import url("https://fonts.googleapis.com/css2?family=Kanit&family=Lato:wght@300;400;700;900&family=Pacifico&family=Permanent+Marker&family=Poppins:ital,wght@0,100;0,200;0,300;0,600;0,700;1,200;1,400;1,500;1,600&family=Uchen&display=swap");


:root {
  --font-family: "Poppins";
  --color-bg: #000000;
  --color-border-line: #fd9c4f;
  --color-navbar-links: #ffffff;
  --color-white: #ffffff;
  --color-primary: #fd9c4f;
}