/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background: black;
    color: white;
  }
  
  header {
    text-align: center;
    background: black;
    color: white; /* Example background color */
    padding: 20px;

  }
  
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    border-radius: 2rem;
  }
  
  main {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  h2 {
    color: #fd9c4f;
  }
  .heading-1{
    color: #fd9c4f;
  }
  p{
    color: #fafafa;
  }
  
  footer {
    background-color: #333; /* Example background color */
    color: #fff; /* Example text color */
    text-align: center;
    padding: 10px 0;
  }
  