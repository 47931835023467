* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  list-style: none;
  font-family: var(--font-family);
}

body {
  background: var(--color-bg);
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}
a {
  color: unset;
  text-decoration: none;
}