.criyahub-container{
    margin: 2rem 6rem;
}
.criyahub-container_mainheading{
    text-align: center;
    margin-bottom: 2rem;
}
.criyahub-container-subheading{
    margin-bottom: 1rem;
}
.criyahub-container_para{
    margin-bottom: 1rem;
}
.criyahub-container_paralast{
    margin-top: 2rem;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .criyahub-container{
        margin: 1rem 1rem;
    }
    .criyahub-container_mainheading{
        text-align: center;
        margin-bottom: 2rem;
    }

}