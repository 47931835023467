.criya_container{
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    align-items: center;
    min-height: 100vh; /* Fills viewport height */
    
}
.image-wrapper {
    display: inline-block; 
    vertical-align: middle; 
    width: 50%; /* Responsive image */
    cursor: pointer; 
  }
.image-wrapper img{
width: 100%;
cursor: pointer;
}

@media (max-width: 768px) {
    .image-wrapper {
        
        width: 100%; /* Responsive image */
        cursor: pointer; 
      }
    .image-wrapper img{
        width: 100%;
        }
  }