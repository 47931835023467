.criya__footer {
    padding: 5rem 3.5rem;
    font-family: var(--font-family);
    background-color: var(--color-footer-bg);
    color: var(--color-bg);
  }
  .criya__footer-links_logo {
    font-family: var(--font-family);
    font-size: 2.5rem;
    margin-bottom: 0.8rem;
  }
  h3 {
    font-family: var(--font-family);
    font-size: 1.2rem;
    margin: 0rem 0rem 1.5rem 0rem;
  }
  h4 {
    font-family: var(--font-family);
    font-size: 1.2rem;
  }
  .criya__footer_li {
    font-size: 0.8rem !important;
    font-family: var(--font-family);
    color: #ffffff;
    padding: 0.7rem 0rem;
    font-weight: bold;
  }
  
  .criya__footer-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
  }
  
  .criya__footer-links_container {
    display: flex;
    padding: 2rem;
    align-items: center;
  }
  
  .criya__footer-menu {
    display: none;
    position: relative;
  }
  .criya__footer-links_logo_about_contact_container {
    width: 50%;
  }
  .criya__footer-links_contact_container {
    display: flex;
    font-family: var(--font-family);
    padding: 1.5rem 0rem;
    gap: 2rem;
  }
  
  .criya__footer-links_contact_container_phone_number {
    font-size: 0.85rem;
  }
  .criya__footer-links_contact_container_emailId {
    font-size: 0.85rem;
    text-decoration: underline;
  }
  .criya__footer-links_logo_about_text {
    font-family: var(--font-family);
    padding: 0rem 2rem;
    padding: 0;
    width: 100%;
  }
  .criya__footer-links_social_container {
    font-family: var(--font-family);
  }
  .criya__footer-links_contact_container_social_icons {
    display: flex;
    gap: 1rem;
    padding: 0.5rem 0rem;
    font-size: 1.5rem;
  }
  .criya__footer-newsletter_input {
    padding: 1rem 3rem 1rem 0.4rem;
    background-color: rgba(255, 255, 255, 0.302);
    border-radius: 0.2rem;
    outline: none;
    border: none;
  }
  .criya__footer-newsletter_input::placeholder {
    color: var(--color-bg);
    font-family: var(--font-family);
    font-size: 0.8rem;
  }
  .criya__footer-course_container,
  .criya__footer-newsletter_container {
    margin-top: 0.8rem;
  }
  
  .criya__footer-careers {
    display: none;
  }
  .criya__footer_li {
    color: var(--color-navbar-links);
    letter-spacing: 0.1rem;
    font-weight: 600;
  }
  .criya__footer-links_contact_container_social_icon {
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    .criya__footer {
      padding: 5rem 2rem;
      font-family: var(--font-family);
    }
    .criya__footer-links_logo {
      font-family: var(--font-family);
      font-size: 2.5rem;
      margin-bottom: 0.8rem;
    }
    h3 {
      font-family: var(--font-family);
      font-size: 1.2rem;
      margin: 0rem 0rem 1.5rem 0rem;
    }
    h4 {
      font-family: var(--font-family);
      font-size: 1.2rem;
    }
    span {
      color: var(--color-orange);
      font-family: var(--font-family);
    }
    .criya__footer_li {
      font-size: 0.8rem !important;
      font-family: var(--font-family);
      color: var(--color-navbar-links);
      padding: 0.7rem 0rem;
      font-weight: bold;
      /* color: var(--color-bg); */
      border: 2px solid red;
    }
  
    .criya__footer-links {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
  
    .criya__footer-links_container {
      display: flex;
      padding: 2rem;
      align-items: center;
      color: var(--color-bg);
    }
  
    .criya__footer-menu {
      display: none;
      position: relative;
    }
    .criya__footer-links_logo_about_contact_container {
      width: 25rem;
    }
    .criya__footer-links_contact_container {
      display: flex;
      flex-direction: column;
      font-family: var(--font-family);
  
      padding: 1.5rem 0rem;
      gap: 2rem;
    }
  
    .criya__footer-links_contact_container_phone_number {
      font-size: 0.85rem;
    }
    .criya__footer-links_contact_container_emailId {
      font-size: 0.85rem;
      text-decoration: underline;
    }
    .criya__footer-links_logo_about_text {
      font-family: var(--font-family);
      padding: 0rem 2rem;
      padding: 0;
      width: 75%;
    }
    .criya__footer-links_social_container {
      font-family: var(--font-family);
    }
    .criya__footer-links_contact_container_social_icons {
      display: flex;
      gap: 1rem;
      padding: 0.5rem 0rem;
      font-size: 1.5rem;
    }
    .criya__footer-newsletter_input {
      padding: 1rem 3rem 1rem 0.4rem;
      background-color: rgba(255, 255, 255, 0.302);
      border-radius: 0.2rem;
      outline: none;
      border: none;
    }
    .criya__footer-newsletter_input::placeholder {
      font-family: var(--font-family);
      font-size: 0.8rem;
    }
    .criya__footer-course_container,
    .criya__footer-newsletter_container {
      margin-top: 0.8rem;
      padding: 0rem 2rem;
    }
    .criya__footer-careers {
      display: none;
    }
  }
  
  @media screen and (max-width: 650px) {
    .criya__footer {
      padding: 3rem 1rem;
      margin: 0rem;
    }
    .criya__footer-links_logo_about_contact_container {
      width: 100%;
    }
    .criya__footer-links {
      flex-direction: column;
      justify-content: flex-start;
    }
    .criya__footer_li {
      display: none;
    }
    .criya__footer-links_logo {
      font-size: 1.3rem;
      margin: 0.5rem 0rem;
    }
    .criya__footer-links_logo_about_text {
      font-size: 0.625rem;
      width: auto;
      max-width: 30rem;
    }
    .criya__footer-links_contact_container {
      padding: 1rem 0rem;
      gap: 1rem;
    }
    .criya__footer-careers {
      display: block;
      padding: 0.8rem 0rem;
      font-size: 0.75rem;
    }
    .criya__footer-newsletter_container {
      display: none;
    }
    h3 {
      font-size: 0.75rem;
      margin: 0.1rem 0rem;
    }
    h4 {
      font-size: 0.75rem;
      padding-bottom: 0.2rem;
    }
    .criya__footer-links_contact_container_social_icons {
      gap: 0.5rem;
      padding: 0.4rem 0rem;
      font-size: 1rem;
    }
    .criya__footer-links_contact_container_phone_number {
      font-size: 0.65rem;
    }
    .criya__footer-links_contact_container_emailId {
      font-size: 0.65rem;
    }
    .criya__footer-course_container {
      margin-top: 0.8rem;
      padding: 0rem 0rem;
    }
  }